import styled from "styled-components";
import { Colors } from "./styled.container";

export const LandingHeroContainer = styled.div`
    color: aliceblue;
    width: 100%;
    height: 100vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    background: ${props => props.url ? `url(${props.url})` : 'none'};
    background-size: cover;
    background-position: bottom;
    object-fit: contain;
`

export const LandingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;


  @media (min-width: 800px) {
    flex-direction: row;
    
  }
`

export const LandingHeroLogo = styled.div`
  width: 80%;
  max-width: 700px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  margin-right: 30px;

  
`

export const LandingHeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  z-index: 2;
  margin: 15px;
  margin-left: 25px;
`

export const LandingHeroTextHeading = styled.div`
  color: ${Colors.card_background};
  font-size: 2rem;
  font-weight: 700;
  align-self: flex-start;
  margin-top: 10px;

  @media (min-width: 800px) {
    font-size: 2.5rem;
  }

  @media (max-width: 350px) {
    font-size: 1.2rem;
  }
`

export const LandingHeroSubtext = styled.div`
  color: rgba(255, 255, 255, 0.8);
  max-width: 400px;
  font-size: 1rem;
  margin-top: 10px;
  font-weight: 600;

  @media (min-width: 800px) {
    font-size: 1.2rem;
  }

  @media (max-width: 350px) {
    font-size: 0.9rem;
  }
`

export const LandingHeroButtonContainer = styled.div`
  display: flex;
  z-index: 2;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
`

export const LandingHeroButton = styled.div`
  border-radius: 20px;

  background-color: ${Colors.dark_grey};
  color: ${Colors.card_background};
  border: 1px solid ${Colors.orange};
  font-weight: 600;
  letter-spacing: 1.1px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  flex: 1;
  margin: 10px 5px;

  cursor: pointer;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  padding: 10px;

  transition: all 0.3s ease-in-out;

  @media (min-width: 800px) {
    min-width: 200px;
  }

  &:hover {
    background-color: ${Colors.dark_orange};

  }
`

export const LandingCardContainer = styled.div`

  width: 100%;
  height: 100%;
  display: flex;



  flex-direction: column;
  
  
  background-color: ${Colors.dark_grey};

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`

export const LandingCardHolder = styled.div`
  
  border-radius: 10px;
  z-index: 2;
  max-width: 1024px;

  position: relative;

  height: 100%;
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    
    flex-direction: ${props => props.swap ? "column-reverse" : "column"};
  }

`

export const LandingCardImageContainer = styled.div`
  display: flex;
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }

`

export const LandingCardImage = styled.img`

  height: 100%;
  max-height: 400px;
  min-height: 500px;
  width: 100%;
  object-fit: cover;
  
  @media (max-width: 800px) {
    min-height: 200px;
    max-height: 300px;
  }
`

export const LandingCard = styled.div`
  

  z-index: 2;
  width: 50%;
  margin: 40px 0px;
  flex-direction: column;

  padding: 0px 30px;
  display: flex;
 
  @media (max-width: 800px) {
    
    width: 100%;
  }
`

export const LandingCardTitle = styled.div`
  color: ${Colors.card_background};
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 8px solid ${Colors.green};
  padding-bottom: 10px;
  letter-spacing: 1.1px;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`

export const LandingCardText = styled.div`
  
  font-size: 0.8rem;
  color: ${Colors.card_background};
  display: flex;
  flex-wrap: wrap;
  line-height: 1.6;
  margin-bottom: 5px;
  flex-direction: ${props => props.row ? "row" : "column"};
  align-items: ${props => props.row ? "center" : "flex-start"};
  margin-top: ${props => props.row ? "10px" : "0px"};
  align-self: ${props => props.centered ? "center" : "flex-start"};
  justify-content: ${props => props.row ? "center" : "flex-start"};

  .title {
    color: ${Colors.orange};
    margin-top: 10px;
    font-size: 0.9rem;
    margin-bottom: 0px;
    font-weight: 600;
  }

  .inline {
    display: inline-block;
    color: ${Colors.orange};
    font-size: 0.8rem;
    font-weight: 600;
    white-space: pre-wrap;
    letter-spacing: 1.1px;
    margin: 0px 5px;
  }

  .text-center {
    text-align: center;
    font-size: 1rem;
    max-width: 500px;
    line-height: 1.8;
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;

    .title {
      font-size: 0.9rem;
      letter-spacing: 1.1px;
    }

    .text-center {
      font-size: 0.8rem;
    }
  }
`

export const LandingServiceContainer = styled.div`
  width: 100%;

  z-index: 2;
  display: flex;
  position: relative;
  background: ${props => props.url ? `url(${props.url})` : 'none'};
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(19, 26, 23, 0.65);
  }
`

export const LandingServiceTitle = styled.div`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 0px 15px;
  z-index: 3;
  margin-bottom: 40px;
  color: ${Colors.white};

  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`

export const LandingButton = styled.div`
  border-radius: 20px;
  
  background-color: ${Colors.white};
  color: ${Colors.dark_grey_green};

  width: 60%;
  max-width: 500px;
  z-index: 3;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 10px;

  transition: all 0.3s ease-in-out;

  @media (min-width: 800px) {
    min-width: 200px;
  }

  &:hover {
    background-color: ${Colors.disabled};

  }
`