import React from 'react'

import ProjectList from './ProjectList'
import Project1aImg from "./img/projects/1a.jpg"
import Project1bImg from "./img/projects/1b.jpg"
import Project1cImg from "./img/projects/1c.jpg"

import Project2aImg from "./img/projects/2a.jpg"
import Project2bImg from "./img/projects/2b.jpg"
import Project2cImg from "./img/projects/2c.jpg"
import Project2dImg from "./img/projects/2d.jpg"

import Project3aImg from "./img/projects/3a.png"
import Project3bImg from "./img/projects/3b.jpg"
import Project3cImg from "./img/projects/3c.png"
import Project3dImg from "./img/projects/3d.jpg"

import Project4aImg from "./img/projects/4a.jpg"
import Project4dImg from "./img/projects/4d.jpg"

import Project5aImg from "./img/projects/5a.jpg"
import Project5bImg from "./img/projects/5b.png"
import Project5cImg from "./img/projects/5c.jpg"

import { Container, SectionHeader, BackImgHolder } from './styles/styled.container'

function ProjectListHolder() {
  return (
    <Container id="projects">
        <BackImgHolder />
        <SectionHeader>Projects</SectionHeader>
        <ProjectList 
        itemData={project1Data} 
        title="Safety improvement project for large cement producer in KZN - (Budget R700 000)"
        date="June 2022 - October 2022" 
        details="Access stairway from silo 1 to bulk out loading bucket elevator head pulley to improve safety, accessibility, and maintainability of bucket elevator"
        />
        <ProjectList 
        itemData={project2Data} 
        title="Repurposed redundant truck washing bay and management building - (Budget R500 000)"
        date="May 2022 - September 2022 " 
        details="Repurposed redundant truck washing bay and management building entrance to increase parking capacity by 11 car parking bays for large cement producer in KZN"
        />

        <ProjectList 
        itemData={project3Data} 
        title="Environmental Control - (Budget R200 000)"
        date="July 2022 - November 2022 " 
        details="Install an air ductwork system to divert hot air generated from compressor exhaust into the atmosphere to improve ergonomics of packing plant."
        />

        <ProjectList 
        itemData={project4Data} 
        title="Bulk Material Handling - (Budget R500 000)"
        date="October 2021 - December 2021" 
        details="Increased material transfer rates of high bulk density product. Improved from 130 tph to 160 tph."
        />

        <ProjectList 
        itemData={project5Data} 
        title="Dust & Environmental Controls - (Budget R1.8m)"
        date="July 2022 - December 2022" 
        details="Control of dust laden air escaping into atmosphere when product silo 1 to 3 is being filled."
        />
    </Container>
  )
}

const project1Data = [
    {
      img: Project1aImg,
      title: 'Before',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: Project1bImg,
      title: 'Construction',
    },
    {
      img: Project1cImg,
      title: 'Handover',
      cols: 1
    },
  ];

  const project2Data = [
    {
      img: Project2aImg,
      title: 'Wash Bay Before',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: Project2cImg,
      title: 'Wash Bay After',
    },
    {
      img: Project2bImg,
      title: 'Parking Before',
      cols: 1
    },
    {
        img: Project2dImg,
        title: 'Parking After',
        cols: 2
      },
  ];

  const project3Data = [
    {
      img: Project3aImg,
      title: 'Concept Design',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: Project3bImg,
      title: 'Entrance Before',
    },
    {
      img: Project3cImg,
      title: 'Duct Design',
      cols: 1
    },
    {
        img: Project3dImg,
        title: 'After Installation',
        cols: 2
      },
  ];

  const project4Data = [
    {
      img: Project4aImg,
      title: 'Conveyer Stringer Before',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: Project4dImg,
      title: 'Conveyer Stringer After',
    },
  ];

  const project5Data = [
    {
      img: Project5aImg,
      title: 'Silo 1 & 2 Roof',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: Project5bImg,
      title: 'Concept Design',
    },
    {
      img: Project5cImg,
      title: 'After dust extraction filter',
    },
  ];

export default ProjectListHolder