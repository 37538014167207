import styled from "styled-components";
import { Colors } from "./styled.container";

export const TestimonialContainer = styled.div`
    
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 10px;

`


export const TestimonialItem = styled.div`
    font-size: 0.8rem;
    width: 100%;
    border-radius: 10px;
    border: 2px solid white;
    font-weight: 600;
    line-height: 1.6;
    padding: 20px;

    background-color: ${Colors.dark_grey_highlight};
    color: ${Colors.white};
    margin: 10px 0px;
    display: flex;
    align-items: center;

    @media (max-width: 700px) {
        flex-direction: column;

        margin: 20px 20px;
    }
`

export const TestimonialText = styled.div`
    display: flex;
    
    font-weight: 400;
    line-height: 1.9;
    margin-right: 30px;
`

export const TestimonialAuthor = styled.div`
    background-color: ${Colors.dark_grey};
    width: 30vw;
    height: 100%;
    color: white;
    border-radius: 10px;
    text-align: left;
    padding: 10px 20px;
    border: 2px solid ${Colors.green};
    margin-top: 10px;

    @media (max-width: 700px) {
        margin-top: 20px;
        width: 100%;
    }
`

