import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import { ProjectDate, ProjectDetails, ProjectListContainer, ProjectTitle } from './styles/styled.projectlist';


export default function ProjectList(props) {
    const {title, date, details, itemData} = props;

  return (
        <ProjectListContainer>
        <ProjectTitle>{title}</ProjectTitle>
        <ProjectDate>{date}</ProjectDate>
        <ProjectDetails>{details}</ProjectDetails>
        <ImageList sx={{ width: '100%', height: '100%' }}>
      
      {itemData.map((item, i) => (
        
        <ImageListItem key={i}>
          <img
            src={item.img}
            srcSet={item.img}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
            
          />
        </ImageListItem>
      ))}
    </ImageList>
        </ProjectListContainer>
        
    
  );
}

