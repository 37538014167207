import React, { useState } from 'react'
import { AppContainer, BackImgHolder } from './styles/styled.container'
import { CardArrowImage, CardCheckbox, CardCheckboxLabel, CardCircle, CardContainer, CardHolder, CardItem, CardItemRow, CardItemText, CardItemTitle, CardNumber, CardTitle, CardTitleHolder, ErrorText, HeroContainer, HeroText, HeroTextTitle, LogisticButton, LogisticsContainer, LogisticsFooter, LogisticsHeader, LogisticsNav, LogisticsNavImage, LogisticsNavImageHolder, LogisticsNavTitle, LogisticsSubmitButton, LogisticsTrackHolder, LogisticsTrackSubtext, LogisticsTrackTableContainer, LogisticsTrackTitle, LogoImageHolder, TableTitleContainer } from './styles/styled.logistics'
import NavImg from "./img/courier.png"
import LogoImg from "../src/img/track_man.png"; // logo_logistics.webp
import { LogoImage } from './styles/styled.container'
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from './styles/styled.container';
import ErrorDialog from './ErrorDialog';
import Footer from './Footer';



const useStyles = makeStyles({
  rowType: {
    color: `${Colors.card_background}`,
    backgroundColor: `${Colors.dark_grey}`,
    fontSize: '0.8rem',
    
  },
  headerCell: {
    color: `${Colors.orange}`,

  },

});

function LogisticsTrack() {
  const [waybill, setWaybill] = useState("");
  const [showLoading, setShowLoading] = useState(false)
  const [waybillData, setWaybillData] = useState();
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const navigate = useNavigate();
  const classes = useStyles();

  const columns = [

    { field: 'date', headerName: 'Date', headerClassName: classes.headerCell, minWidth: 120, maxWidth: 150, flex: 1},
    { field: 'time', headerName: 'Time', headerClassName: classes.headerCell, minWidth: 100, maxWidth: 150, flex: 1},
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 300,
      headerClassName: classes.headerCell,
      flex: 1
    },
    
  ];

  const handleTrack = async () => {

    if(waybill.length === 0) return;
    setWaybillData();
    setShowLoading(true)

    try {
      const response = await fetch(`https://us-central1-ckcm-server.cloudfunctions.net/app/track/${waybill}`);
      
      if (response.status === 200) {
        const data = await response.json();
        //console.log("Got result", data);
        setWaybillData(data);
      } else {
        //console.error('Response not OK:', response.error);
        setShowErrorDialog(true)
      }
    } catch (error) {
      console.error(error);
    }

    setShowLoading(false)
  };



  const getRowClassName = (row) => {

    return classes.rowType;
  };

  function CustomHeader(props) {
  
    return <div className={classes.headerCell}>{props.title}</div>;
  }

  function handleWaybillChange(value) {
    if(waybillData) setWaybillData();

    setWaybill(value)
  }

  return (
    <LogisticsContainer>
        <Loader show={showLoading} />

        <ErrorDialog
        open={showErrorDialog}
        setOpen={setShowErrorDialog}
        message="Your waybill number could not be found."
        second_message="Are you sure you typed it in correctly? If this issue persists, please contact info@ckcm.co.za" />

        <BackImgHolder />


        <LogisticsNav onClick={() => navigate("/logistics")}>
            <LogisticsNavImageHolder>
                <LogisticsNavImage src={NavImg} />
            </LogisticsNavImageHolder>
            <LogisticsNavTitle>CKCM Logistics</LogisticsNavTitle>
        </LogisticsNav>

        <LogisticsHeader margin_bottom="-20px">
            <LogoImageHolder width='150px'>
                <LogoImage src={LogoImg} />
            </LogoImageHolder>
            
            
        </LogisticsHeader>
        <LogisticsTrackHolder>
        <LogisticsTrackTitle>Track your parcel</LogisticsTrackTitle>
            <LogisticsTrackSubtext>Get a real time update of your parcel by entering your waybill number</LogisticsTrackSubtext>

        <CardItem style={{'width': '90%', 'maxWidth': '300px', 'margin': '20px'}}>
            <CardItemText onChange={(e) => handleWaybillChange(e.target.value)} />
        </CardItem>

        
        <LogisticsSubmitButton onClick={handleTrack}>Track</LogisticsSubmitButton>

        </LogisticsTrackHolder>
        
        {waybillData && <LogisticsTrackTableContainer>
          <TableTitleContainer>Tracking Status for {waybill}</TableTitleContainer>
        
        <DataGrid
                autoHeight
                getRowClassName={getRowClassName}
                
                pageSize={10}
                rowsPerPageOptions={10}
                sx={{'border': '0'}}
                
                
                            
                            rows={waybillData}
                            //rows={filterActions===true ? storeVisits.filter(visit => visit.actions_outstanding > 0) : storeVisits}
                columns={columns}
                          
        />
        </LogisticsTrackTableContainer>}
        
        
    </LogisticsContainer>
  )
}

export default LogisticsTrack