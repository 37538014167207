import { TextField } from '@mui/material'
import React, {useState} from 'react'
import { Container } from './styles/styled.container'
import { FormButton, FormContainer, FormItem, FormItemField, FormItemText, FormMessage, FormRow} from './styles/styled.form'
import emailjs from '@emailjs/browser';

function Form() {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");

    function sendEmail() {
        const service_id = "service_2v6ha1j"
        const template_id = "template_lnyozjq"
        const user_id = "mpEbW728K3AF_4SHG"

        if(name === "" || email === "" || message === "") {
            setStatus("Please fill in your contact details.")
            return;
        }

        const formData = {
            firstName: name,
            email: email,
            mobile: mobile,
            message: message
        }

        emailjs.send(service_id, template_id, formData, user_id).then((result) => {
            console.log("Email sent successfully!")
            setStatus("Thank you for contacting CKCM solutions. We will be in touch with you shortly.")
        }).catch(err => console.log(err.message))

    }

  return (
    <Container inherit>
        <FormContainer>
            <FormRow>
                <FormItem>
                    <FormItemText>Name</FormItemText>
                    <FormItemField type="text" value={name} onChange={e => setName(e.target.value)} />
                </FormItem>
            </FormRow>
            <FormRow>
                <FormItem>
                    <FormItemText>Mobile Number</FormItemText>
                    <FormItemField type="text" value={mobile} onChange={e => setMobile(e.target.value)} />
                </FormItem>
            </FormRow>
            <FormRow>
                <FormItem>
                    <FormItemText>Email Address</FormItemText>
                    <FormItemField type="text" value={email} onChange={e => setEmail(e.target.value)} />
                </FormItem>
            </FormRow>
            
            <FormRow>
                <FormItem>
                    <FormItemText>Message</FormItemText>
                    <TextField fullWidth value={message} onChange={e => setMessage(e.target.value)} InputProps={{ disableUnderline: true }} sx={{backgroundColor: 'white', borderRadius: '5px', display: "flex", flex: "1", padding: '5px'}} variant='standard' multiline />
                </FormItem>
            </FormRow>
            {status && <FormRow>
                <FormMessage>{status}</FormMessage>
            </FormRow>}
            <FormRow>
                <FormButton onClick={() => sendEmail()}>Send</FormButton>
            </FormRow>
        </FormContainer>
    </Container>
  )
}

export default Form