import styled from "styled-components";
import { Colors } from "./styled.container";

export const ContactHolder = styled.div`
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    

    @media (max-width: 800px) {
        flex-direction: column;
    }
`

export const ContactInfo = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 110%;
    
    @media (max-width: 800px) {
        
        margin-bottom: 30px;
        justify-content: center;
        align-items: center;
    }
`

export const SocialHolder = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
   justify-content: center;

    @media (max-width: 800px) {
        
        
        justify-content: center;
        align-items: center;
    }
`

export const SocialItem = styled.div`
    &:hover {
        cursor: pointer;
    }
`

export const ContactHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    margin-bottom: 10px;
    max-width: 600px;
    font-weight: 600;
    z-index: 10;
    font-size: 2.5rem;
    text-align: center;
    color: ${props => props.color ? props.color : `${Colors.dark_grey}`} ;
    

    @media (max-width: 600px) {
        font-size: 1.5rem;
    }
`