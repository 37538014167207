import styled, {keyframes} from "styled-components";
import { Colors } from "./styled.container";

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

export const LogisticsContainer = styled.div`
    width: 100%;

    min-height: 100vh;
    display: flex;
    position: relative;

    flex-direction: column;

    background-color: ${Colors.dark_grey};
`

export const LogisticsNav = styled.div`
    padding: 10px 20px;
    width: 100%;
    z-index: 2;
    cursor: pointer;
    display: flex;

    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    margin-bottom: 20px;
    background-color: ${Colors.dark_grey_highlight};
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #212529, #212529);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #212529, #212529); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

`

export const LogisticsNavImageHolder = styled.div`
    width: 30px;
    margin-right: 20px;

`

export const LogisticsNavImage = styled.img`
    width: 100%;
    object-fit: contain;
`

export const LogisticsNavTitle = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    color: ${Colors.white};
`

export const LogisticsHeader = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    align-self: center;
    margin-top: 20px;
    margin-bottom: ${props => props.margin_bottom ? props.margin_bottom : '-60px'};
    z-index: 5;
`

export const LogisticsHeaderTitle = styled.div`
    font-size: 2.5rem;
    text-align: center;
    z-index: 2;
    margin-bottom: 20px;
    padding: 0px 20px;
    max-width: 500px;
    letter-spacing: 1.1px;
    font-weight: 700;
    color: ${Colors.card_background};

    @media (max-width: 600px) {
        font-size: 2rem;
        max-width: 400px;
    }

    @media (max-width: 400px) {
        font-size: 1.5rem;
        
    }
`

export const LogoImageHolder = styled.div`
    max-width: ${props => props.width ? props.width : '300px'};
    display: flex;
    align-self: center;
    justify-content: center;

`

export const LogoImage = styled.img`
    width: 100%;

    object-fit: fill;
`

export const LogoTitle = styled.div`
    color: ${Colors.dark_grey};
    font-size: 1.5rem;
    font-weight: 600;
`

export const HeroContainer = styled.div`
    display: flex;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 15px 20px;
    background-color: ${Colors.dark_grey_highlight};
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #212529, #212529);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #212529, #212529); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 10px;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    margin: 10px 0px;

    @media (max-width: 500px) {
        margin: 20px 10px;
    }
`

export const HeroTextTitle = styled.div`
    font-size: 1.5rem;
    align-self: center;
    font-weight: 600;

    text-align: center;
    color: ${Colors.white};

    @media (max-width: 500px) {
        font-size: 1.2rem;
    }
`

export const HeroText = styled.div`
    font-size: 0.9rem;
    align-self: center;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-align: center;
    color: ${Colors.orange};

    @media (max-width: 500px) {
        font-size: 0.8rem;
    }
`

export const HeroImageContainer = styled.div`
    display: flex;
    max-width: 100px;
    margin-left: 40px;
`

export const HeroImage = styled.img`
    width: 100%;
    object-fit: contain;
`

export const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    
`

export const CardHolder = styled.div`
    display: flex;
    z-index: 2;
    width: 100%;
    margin-bottom: 20px;
    color: ${Colors.card_background};
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
`

export const CardArrowImage = styled.img`
    width: 100px;
    z-index: 2;
    object-fit: contain;
    display: none;

    @media (min-width: 1400px) {
        display: inline-block;
    }
`

export const CardContainer = styled.div`
    background-color: ${Colors.dark_grey_blue};
    display: flex;
    
    /* box-shadow: rgba(0, 204, 147, 1) 0px 1px 8px;  */
    width: 400px;
    min-height: 500px;
    flex-direction: column;
    margin: 30px;
    padding: 30px 30px;
    border-radius: 10px;

    border: ${props => props.errorState===true ? `4px solid ${Colors.green}` : "0"};

    @media (max-width: 600px) {
        min-height: 0;
    }
`

export const CardTitleHolder = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`

export const CardTitle = styled.div`
    font-size: 0.9rem;
    color: ${Colors.orange};
    margin-left: 5px;
    font-weight: 500;

`

export const CardCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid ${Colors.dark_orange};
  animation: ${pulseAnimation} 2s infinite;
`;

export const CardNumber = styled.div`
    font-size: 0.8rem;
    color: ${Colors.orange};
`

export const CardCheckbox = styled.input`
    margin-right: 10px;
    margin-left: 2px;
    transform: scale(1.3);
`

export const CardCheckboxLabel = styled.label`
    font-size: 0.7rem;
    margin-top: 10px;
    background-color: ${Colors.dark_grey};
    padding: 10px 15px;
    line-height: 1.6;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    font-weight: 400;
    align-items: center;
    color: ${Colors.card_background};

    @media (max-width: 400px) {
        font-size: 0.7rem;
    }
`


export const CardItemWrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    

`

export const CardItem = styled.div`
    width: 100%;
    z-index: 2;
    display: flex;
    margin: 10px 0px;
    flex-direction: column;
`

export const CardItemTitle = styled.div`
    font-size: 0.7rem;
    color: ${Colors.card_background};
    margin-bottom: 5px;
`

export const CardItemText = styled.input`
    outline: 0;
    border: 0;
    font-size: 0.9rem;
    background-color: ${Colors.card_background};
    padding: 5px 7px;
    text-align: center;
    margin-top: 5px;
    width: 100%;
    border-radius: 20px;

    &:focus {
        box-shadow: rgba(0, 204, 147, 0.25) 0px 1px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
`

export const CardItemRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const CardItemRowItem = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
`

export const LogisticButton = styled.button`
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 50px;
    z-index: 2;
    background-color: ${Colors.green};
    color: ${Colors.white};
    font-size: 0.9rem;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    width: 200px;
    display: flex;
    align-self: center;
    justify-content: center;
    font-weight: 500;

    &:disabled {
    background-color: #e6e6e6;
    color: #808080;
    cursor: not-allowed;
    }
`

export const ErrorText = styled.div`
    display: flex;
    margin-top: 10px;
    align-self: center;
    z-index: 2;
    color: ${Colors.orange};
    font-size: 0.9rem;
    text-align: center;

    @media (max-width: 500px) {
        font-size: 0.8rem;
    }
`

export const CaptchaContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-self: center;
    z-index: 2;
`

export const IconHolder = styled.div`
    display: flex;
    align-items: center;
`

export const IconImage = styled.img`
    width: 50px;
    object-fit: contain;
    margin-right: 20px;
`

export const LogisticsFooter = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 15px;
    display: flex;
    font-size: 0.7rem;
    color: ${Colors.white};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    background-color: ${Colors.dark_grey_highlight};
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #212529, #212529);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #212529, #212529); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

export const LogisticsTrackHolder = styled.div`
    width: 90%;
    align-self: center;
    padding: 20px 0px;
    padding-bottom: 40px;
    max-width: 1024px;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    background-color: ${Colors.dark_grey_green};
    border-radius: 20px;
`

export const LogisticsTrackTitle = styled.div`
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    font-weight: 600;
    letter-spacing: 1.1px;
    
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0px;
    margin-top: 20px;
    font-size: 1.4rem;
    color: ${Colors.green};

    @media (max-width: 500px) {
        font-size: 1.4rem;
    }
`

export const LogisticsTrackSubtext = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    margin-bottom: 10px;
    padding: 0px 20px;
    font-size: 0.9rem;
    max-width: 400px;
    z-index: 4;
    color: ${Colors.card_background};

    @media (max-width: 600px) {
        font-size: 0.8rem;
    }
`

export const LogisticsSubmitButton = styled.div`
    border-radius: 10px;
    padding: 5px 10px;
    z-index: 2;
    font-weight: 500;
    
    border: 3px solid ${Colors.dark_orange};
    cursor: pointer;
    font-size: 0.9rem;
    color: ${Colors.white};
    text-align: center;
    width: 40%;
    max-width: 300px;
    align-self: center;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: ${Colors.dark_orange};
    }
`

export const LogisticsTrackTableContainer = styled.div`
    width: 90%;
    max-width: 1024px;
    align-self: center;
    margin: 20px;
    z-index: 3;

    background-color: ${Colors.dark_grey};
    
    border-radius: 10px;
    

`

export const TableTitleContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 0.8rem;
    border-radius: 10px;
    background: ${Colors.green_shadow};
    color: ${Colors.dark_grey};
    margin-bottom: 10px;
`