import React, { Fragment } from "react";
import { CenteredHeader, CenteredText, Container, AboutImageHolder, AboutImage, ContainerHolder, SymbolHolder, SymbolImage, SymbolContainer, AboutBack, SymbolTitle, SymbolTextContainer } from "./styles/styled.container";
import PointImg from "./img/point.png";

import { Colors } from "./styles/styled.container";
import AboutImg from "./img/about_symbol.webp"
import { AboutContainer, AboutInfoContainer, SymbolContainerHolder } from "./styles/styled.container";

function About() {
    return(
        <Fragment>
            
            <ContainerHolder id="about">
            <AboutBack />
            <Container>
            <AboutContainer>

            <AboutImageHolder>
                <AboutImage src={AboutImg} />
            </AboutImageHolder>
            <AboutInfoContainer>
            
            <CenteredHeader>
            Our Story
            </CenteredHeader>
                
            <CenteredText>CKCM Solutions was established in 2021 by Clayton Naidoo as a platform to purse his passion for technology and practice his craft of reliability engineering as a freelance engineering consultant. The company specializes in providing professional engineering services, training and customized physical asset management, project management and maintenance solutions.</CenteredText>

            <CenteredHeader style={{'color': `${Colors.green}`}}>
                Hi, I'm Clayton
            </CenteredHeader>
            <CenteredText>I am an engineering technologist with over 15 years of experience in asset management and reliability in the mining sector. I have a passion for technology, people and a deep understanding of what physical asset management and maintenance is all about.</CenteredText>
            
            </AboutInfoContainer>

            
            </AboutContainer>
            
            <SymbolContainerHolder>
            <SymbolContainer>
                <SymbolHolder>
                    <SymbolImage src={PointImg} />
                    <SymbolTextContainer>
                    <SymbolTitle>Philosophy</SymbolTitle>
                    To build lasting relationships with our partners, clients that is based on integrity, trust and shared value.
                    </SymbolTextContainer>
                   
                </SymbolHolder>
                <SymbolHolder>
                    <SymbolImage src={PointImg} />
                    <SymbolTextContainer>
                    <SymbolTitle>Vision</SymbolTitle>
                    To provide professional engineering solutions that improves safety, enhances operating standards, increase skills and delivers real value to our clients, partners and communities that we service.
                    </SymbolTextContainer>
                </SymbolHolder>
                <SymbolHolder>
                    <SymbolImage src={PointImg} />
                    <SymbolTextContainer>
                        <SymbolTitle>Mission</SymbolTitle>
                        To implement engineering principles and technology to make the workplace safer, machines more reliable and eliminate waste.

                    </SymbolTextContainer>
                </SymbolHolder>
                
            </SymbolContainer>
            </SymbolContainerHolder>
            
            


        </Container>
        </ContainerHolder>
        </Fragment>
        
        
        
    )
}

export default About;