import styled from "styled-components";
import { Colors } from "./styled.container";

export const FooterContainer = styled.div`
    width: 100%;

    font-size: 0.6rem;
    
    border-radius: 5px;
    color: ${Colors.card_background};
    font-weight: 400;
    background-color: ${Colors.dark_grey_green};
    display: flex;
    flex-direction: column;

    padding: 10px 0px;
    padding-left: 20px;
    justify-content: center;
    position: relative;
    bottom: 0;
    cursor: pointer;
    
`

export const FooterItem = styled.div`
    padding: 2px 0px;
    font-size: 0.6rem;
    letter-spacing: 1.1px;

`