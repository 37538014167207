import React from "react";

import { ServicesContainer, ServicesImage } from "./styles/styled.services";
import ServiceImg from "./img/services3.webp";

function Services() {
    return(
        <ServicesContainer id="services">
            
            <ServicesImage src={ServiceImg} />
            
        </ServicesContainer>
        
        
    )
}

export default Services;