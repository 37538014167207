import styled from "styled-components";
import AboutBackImg from "../img/about_back2.jpg"
import BackImg from "../img/logo_symbol.webp"

export const Colors = {
    blue: "#4361ee",
    light_grey: "#f8f9fa",
    dark_grey: "#212529",
    dark_grey_green: "#131a17",
    very_dark_grey: "#191B1D",
    process_background: "#FBB03B",
    dark_grey_blue: "#262F37",
    dark_grey_highlight: "#3d4146",
    green_card: "#0D684C",
    grey_shadow_text: "#C5C7C6",
    white: "white",
    subtitle: "#f72585",
    card_background: "#f8f9fa",
    green: "#00cc93",
    green_highlight: "#0dc98e",
    green_shadow: "#0ab580",
    orange: "#FBB03B",
    dark_orange: "#E88400",
    disabled: "#ced4da",
    navbar: "#EBEFF2"
}

export const BackImgHolder = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 20%;
    position: absolute;
    overflow: hidden;
    background-image: url(${BackImg});
    background-size: cover;
`

export const AppContainer = styled.div`
    width: 100%;
    position: relative;
    background-color: ${Colors.dark_grey};   
`

export const AppLogo = styled.img`
    width: 80px;
    height: 60px;
    padding-right: 20px;
`

export const LogoImage = styled.img`
    width: 90%;
    height: auto;
`

export const Image = styled.img`
    width: 100px;
    height: 150px;
    
    align-self: center;
`

export const ChartImage = styled.img`
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`

export const ContainerHolder = styled.div`
    display: flex;
    position: relative;
    background-color: ${Colors.dark_grey};
    width: 100%;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
        flex-direction: column-reverse;
    }
`

export const AboutBack = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 10%;
    position: absolute;
    background-image: url(${AboutBackImg});
    background-size: cover;
    background-position: right;

`

export const ContainerSideImage = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px;
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    @media (max-width: 900px) {
        margin-top: 10px;
        margin-bottom: 50px;
    }
`

export const Container = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;
    padding: 10px 40px;
    position: relative;
    flex-direction: column;
    color: white;
    padding-bottom: 20px;
    
    background-color: ${props => props.color ? props.color : "#212529"};

    ${props => props.inherit &&
    `
    background-color: inherit;
    `
    }

    ${props => props.contact && 
    `
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin: 5px 0px;
    margin-bottom: 0px;
    padding: 20px 0px;

    color: ${Colors.card_background};
    background-color: ${Colors.dark_grey_highlight};
    background: #1f4037;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #00cc93, #1f4037);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #00cc93, #1f4037); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;`}

    @media (min-width: 800px) {
        font-size: 1.2rem;
    }
`

export const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 3px solid ${Colors.orange};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(255, 255, 255, 0.7) 0px 1px 3px -1px;
    margin-top: 20px;
    font-weight: 700;
    z-index: 10;
    font-size: 3rem;
    text-align: center;
    color: ${props => props.color ? props.color : `${Colors.orange}`};
    text-transform: uppercase;

    @media (max-width: 600px) {
        font-size: 2rem;
    }

    @media (max-width: 350px) {
        font-size: 1.5rem;
    }
`

export const CenteredBlock = styled.div`
    display: flex;
    
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
        flex-direction: column-reverse;
    }

`

export const CenteredHeader = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 20px;
    font-weight: 700;
    z-index: 10;
    font-size: 2.5rem;
    text-align: center;
    color: ${props => props.color ? props.color : `${Colors.white}`};
    text-transform: uppercase;

`
export const CenteredText = styled.div`
    display: flex;
    padding-top: 20px;
    max-width: 600px;
    align-self: center;
    text-align: center;
    line-height: 1.9;
    
    border-radius: 10px;
    align-items: center;
    font-weight: ${props => props.bold ? "700" : null};
    justify-content: center;
    text-align: center;
    font-size: 0.9rem;
    color: ${props => props.color ? props.color : "white"};

    @media (min-width: 800px) {
        font-size: 0.9rem;
    }
`

export const Text = styled.p`
    display: flex;
    padding-top: 20px;
    line-height: 1.9;
    text-align: center;
    align-items: center;
    font-weight: ${props => props.bold ? "700" : null};
    font-size: 0.7rem;
    color: ${props => props.color ? props.color : "white"};

    @media (min-width: 800px) {
        font-size: 0.9rem;
    }
`

export const WhatsappButton = styled.div`
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    bottom: 0;
    z-index: 400;
    right: 0;
    margin-right: 15px;
    margin-bottom: 15px;

    &:hover {
        cursor: pointer;
    }
`

export const ContainerTable = styled.table`
    border: 4px dotted white;
    border-radius: 10px;
    margin: 50px 10px;
    padding: 10px;
    
`

export const ContainerTableRow = styled.tr`
    
    text-align: center;
`

export const ContainerTableColumn = styled.td`
    text-align: center;
    padding: 10px;   
`

export const ContainerTableHeader = styled.thead`
    text-align: center;
    column-span: 2;
    border: 2px solid white;
`

export const AppLink = styled.a`
    text-decoration: none;
    z-index: 500;
    color: black;

    &:link, &:active, &:visited {
        color: black;
    }

    &:hover {
        cursor: pointer;
    }
`
export const SymbolContainer = styled.div`
    display: flex;
    max-width: 1024px;
    

    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    flex-wrap: wrap;
    margin: 30px 0px;
    margin-top: 40px;

`

export const SymbolContainerHolder = styled.div`
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: center;
`

export const SymbolHolder = styled.div`
    display: flex;
    margin: 10px;
    align-items: center;
    
    font-size: 0.8rem;
`

export const SymbolTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const SymbolTitle = styled.div`
    color: ${Colors.green};

    font-weight: 700;
    margin-bottom: 5px;
    font-size: 1rem;
    
`

export const SymbolTitleText = styled.div`
`

export const SymbolImage = styled.img`
    width: auto;
    margin-right: 15px;
    height: 20px;
`

export const SymbolText = styled.div`
    font-size: 1rem;
    font-weight: 600;
    margin-top: 20px;
    text-transform: uppercase;
`

export const ProjectMainContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    
    border-radius: 10px;
    @media(max-width: 900px) {
        flex-direction: column;
    }
    
`

export const AboutContainer = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
    }
`

export const AboutInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 600px;
`

export const AboutImage = styled.img`
    object-fit: contain;
    width: 100%;
    border-radius: 10px;
    flex: 1;
    -webkit-filter: drop-shadow(5px 5px 5px #212529);
    filter: drop-shadow(1px 1px 1px #212529);

    @media (max-width: 900px) {
        margin-top: 50px;
    }

`

export const AboutImageHolder = styled.div`
    display: flex;
    flex: 1;

    max-width: 400px;
    z-index: 20;
    margin-right: 80px;

    @media (max-width: 900px) {
        margin-right: 0px;
        max-width: 300px;
    }
`

export const ProjectImageHolder = styled.div`
    display: flex;
    flex: 1;
    max-width: 800px;
    z-index: 20;
`

export const ProjectImage = styled.img`
    object-fit: cover;
    width: 100%;
    
    border-radius: 10px;
    object-position: left;
    height: 100%;
    flex: 1;

    @media(max-width: 900px) {
        height: 600px;
    }
`

export const ProjectContainer = styled.div`
    display: flex;
    flex: 1;
    
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    flex-wrap: wrap;

`

export const ProjectHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    height: auto;
    max-width: 500px;
    width: 100%;
    padding: 30px 10px;
    background-color: ${Colors.dark_grey};
    border-radius: 5px;
    text-align: center;
    border-bottom: 20px solid ${Colors.green};
    

`

export const ProjectNoImg = styled.img`
    width: 80px;
    height: 80px;
    margin-bottom: 40px;
`

export const ProjectText = styled.div`
    color: ${Colors.white};
    background-color: ${Colors.dark_grey_highlight};
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #434343, #2d2d2d);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #434343, #2d2d2d); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px 10px;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    display: flex;
    align-self: center;
    z-index: 20;
`