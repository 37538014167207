import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Landing from './Landing';
import LogisticsHome from './LogisticsHome';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/logistics/*" element={<LogisticsHome />} />
        <Route path="/engineering" element={<App />} />
      </Routes>
    </BrowserRouter>

);

