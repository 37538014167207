import styled from "styled-components"
import { Colors } from "./styled.container"
import ServiceBackImg from "../img/service_back.png"

export const LogisticsHolder = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${Colors.dark_grey};
`

export const LogisticsContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: space-between;
    
    min-height: 100vh;
    background-color: ${Colors.dark_grey};

    @media (max-width: 800px) {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

    }
`

export const LogisticsVideo = styled.video`
    
    object-fit: cover;
    width: 100%;
    
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 0;
    
`

export const LogisticsVideoOverlay = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
`


export const LogisticsHeroContainer = styled.div`
    width: 100%;
    max-width: 800px;

    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 600px) {
        justify-content: flex-start;
    }
`

export const LogisitcsHeroLogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0px 40px;
    width: 100%;

    max-width: 400px;

    @media (max-width: 800px) {
        align-items: flex-start;
        max-width: 300px;
        margin: 20px;
    }

    @media (max-width: 400px) {
        max-width: 200px;
    }
`


export const LogisticsHeroLogoImage = styled.img`
    width: 100%;
    object-fit: contain;

    display: flex;
    align-items: flex-start;
    align-self: flex-start;
`

export const LogisticsHeroBottomImageContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    
    max-width: 800px;
`

export const LogisticsHeroBottomImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const LogisticsBoxContainer = styled.div`
    display: flex;
    z-index: 1;
    
    justify-content: center;
    width: 100%;
    


    @media (max-width: 800px) {
        position: relative;
        justify-content: flex-end;

        margin-right: 30px;
        align-self: flex-end;
        
    }
`

export const LogisticsBoxImage = styled.img`
    width: 100%;
    max-width: 400px;
    object-fit: contain;

    @media (max-width: 800px) {
        max-width: 250px;
    }

    @media (max-width: 500px) {
        max-width: 200px;
    }

`

export const LogisticsHeroTextContainer = styled.div`
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0px 60px;
    height: fit-content;
    color: ${Colors.card_background};

    @media (max-width: 800px) {
        margin: 0px 30px;
        font-size: 2rem;
        max-width: 400px;
        
    }

    @media (max-width: 500px) {
        font-size: 1.5rem;
    }

    @media (max-width: 300px) {
        font-size: 1.2rem;
        
    }
`

export const LogisticsButtonContainer = styled.div`
    display: flex;

    margin: 0px 60px;
    margin-top: 20px;
    max-width: 350px;

    @media (max-width: 800px) {
        margin: 0px 20px;
        margin-top: 20px;
    }

    @media (max-width: 350px) {
        flex-direction: column;
        
        margin: 0px 20px;
        margin-top: 20px;
    }
`

export const LogisticsHomeButton = styled.button`
    padding: 10px 30px;
    margin: 5px;
    z-index: 2;
    background-color: ${Colors.green_highlight};
    outline: 0;
    border: 0;
    color: ${Colors.dark_grey};
    font-size: 1rem;
    
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
    
    display: flex;
    justify-content: center;
    font-weight: 500;
    transition: all 0.3s ease-in-out;

    @media (max-width: 600px) {
        font-size: 0.9rem;
    }

    &:hover {
        background-color: ${Colors.green_shadow};
        transform: scale(0.98);
    }
`

export const LogisticsOrderContainer = styled.div`
    width: 90%;
    margin: 150px 0px;
    max-width: 800px;
    border-radius: 20px;
    align-self: center;
    min-height: 300px;
    background-color: ${Colors.green_highlight};
    background-image: url(${ServiceBackImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    position: relative;
    
    padding: 20px;

    @media (max-width: 600px) {
        margin: 80px 0px;
    }
`

export const LogisticsServicesManImage = styled.img`
    position: absolute;
    bottom: -20px;
    right: -50px;
    width: 300px;

    @media (max-width: 600px) {
        width: 200px;
        right: -20px;
    }

    @media (max-width: 400px) {
        width: 140px;
    }
`

export const LogisticsServicesTitle = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 2.2rem;
    letter-spacing: 1.2px;
    color: ${Colors.card_background};
    text-transform: uppercase;

    @media (max-width: 600px) {
        font-size: 1.7rem;
    }
`

export const LogisticsOrderCardContainer = styled.div`
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    margin-top: 20px;

    @media (max-width: 600px) {
        
        max-width: none;
        justify-content: center;
        
    }
`

export const LogisticsOrderCard = styled.div`
    display: flex;
    border-radius: 20px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 50%;
    
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    
    background-color: ${Colors.green_card};


`

export const LogisticsOrderCardTitle = styled.div`
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 5px;
    color: ${Colors.card_background};
    font-weight: 600;
    
    @media (max-width: 600px) {
        font-size: 0.9rem;
    }

    @media (max-width: 400px) {
        font-size: 0.8rem;
    }

`

export const LogisticsOrderCardText = styled.div`

    font-weight: 500;
    
    color: ${Colors.grey_shadow_text};
    font-size: 0.8rem;

    @media (max-width: 600px) {
        font-size: 0.7rem;
    }
`

export const LogisticsOrderCardButton = styled.div`
    margin-top: 40px;
    border-radius: 10px;
    max-width: 200px;
    border: 3px solid ${Colors.green_highlight};
    text-align: center;
    padding: 5px 20px;
    cursor: pointer;
    color: ${Colors.card_background};
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3 ease-in-out;

    &:hover {
        border: 3px solid ${Colors.green_shadow};
    }
`

export const LogisticsTrustedContainer = styled.div`
    background-color: ${Colors.dark_grey_blue};
    width: 100%;

    padding: 50px;
    display: flex;
    flex-direction: column;
`

export const LogisticsTrustedTitle = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    padding: 10px 40px;
    background-color: ${Colors.dark_grey_green};
    border-radius: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${Colors.card_background};
`

export const LogisticsTrustedCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

export const LogisticsTrustedCard = styled.div`
    display: flex;
    max-width: 250px;
    max-height: 100px;
    margin: 10px;
    align-items: center;
    justify-content: center;
`

export const LogisticsTrustedImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const LogisticsServicesLayoutContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 100px;
    flex-direction: column;
`

export const LogisticsServicesLayoutCardLeft = styled.div`
    width: 100%;
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;
    display: flex;

    @media (max-width: 700px) {
        flex-direction: column;
        
    }
    
`

export const LogisticsServicesLayoutCardRight = styled.div`
    width: 100%;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
    display: flex;

    @media (max-width: 450px) {
        flex-direction: column-reverse;
        padding: 0px 20px;
    }
    
`

export const LogisticsServicesLayoutCardNumber = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    
    text-align: right;
    right: 0;
    font-size: 12rem;
    z-index: 1;
    
    display: flex;
    justify-content: center;
    text-align: center;

    color: ${Colors.very_dark_grey};
    font-weight: 700;

    @media (max-width: 700px) {
        font-size: 5rem;

        
    }

`

export const LogisticsServicesLayoutCardTextContainerRight = styled.div`
    display: flex;
    flex-direction: column;
    
    padding-left: 20px;
    justify-content: center; 
    min-width: 200px;
    min-height: 300px;
    position: relative;
    flex: 1;
    z-index: 2;
    
    @media (max-width: 700px) {
        padding-left: 0px;
    }

    @media (max-width: 600px) {
        margin-left: 30px;
    }

    @media (max-width: 400px) {
        min-width: 100px;
    }
`


export const LogisticsServicesLayoutCardTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 150px;

    overflow: hidden;
    justify-content: center;
    min-width: 200px;
    min-height: 300px;
    position: relative;
    flex: 1;
    z-index: 2;
    
    @media (max-width: 700px) {
        padding-left: 20px;
    }

    @media (max-width: 600px) {
        margin-right: 30px;

    }

    @media (max-width: 400px) {
        min-width: 100px;
        
    }

`

export const LogisticsServicesLayoutCardTextTitle = styled.div`
    font-size: 3rem;
    color: ${Colors.orange};
    font-weight: 600;
    border-left: 8px solid ${Colors.dark_orange};
    padding-left: 20px;
    max-width: 400px;
    z-index: 2;
    align-self: center;
    line-height: 1.2;
    margin-bottom: 20px;

    @media (max-width: 800px) {
        font-size: 1.5rem;
        align-self: flex-start;
    }

    @media (max-width: 500px) {
        font-size: 1rem;
    }
`

export const LogisticsServicesLayoutCardTextExplanation = styled.div`
    font-size: 1.1rem;
    
    max-width: 400px;
    z-index: 2;
    line-height: 1.7;
    align-self: center;
    
    color: ${Colors.card_background};

    & > span {
        color: ${Colors.orange};
        font-weight: 600;
    }

    & > ul {
        margin-top: 20px;
        border: 1px dashed ${Colors.dark_orange};
        border-radius: 5px;
        font-size: 0.9rem;
        padding: 5px 15px;
        padding-top: 10px;

        & > li {
            margin-bottom: 5px;
            margin-left: 10px;
            line-height: 1.5;

            & > span {
                color: ${Colors.orange};
                font-weight: 600;
            }
        }
    }

    

    @media (max-width: 500px) {
        font-size: 0.8rem;

        & > ul {
            font-size: 0.7rem;
        }
    }
`

export const LogisticsServicesLayoutCardImageContainer = styled.div`
    display: flex;
    max-width: 400px;
    margin-right: 50px;
    z-index: 2;


    /* margin-right: ${props => props.margin_right ? props.margin_right : "0px"};
    margin-left: ${props => props.margin_left ? props.margin_left : "0px"}; */
`

export const LogisticsServicesLayoutCardImageContainerRight = styled.div`
    display: flex;
    max-width: 400px;
    z-index: 2;
    margin-left: 50px;
    

    /* margin-right: ${props => props.margin_right ? props.margin_right : "0px"};
    margin-left: ${props => props.margin_left ? props.margin_left : "0px"}; */
`

export const LogisticsServicesLayoutCardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const LogisticsProcessHolder = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    background-color: ${Colors.process_background};
    width: 100%;
    padding: 50px 50px;
`

export const LogisticsProcessTitle = styled.div`
    font-size: 2.5rem;
    color: ${Colors.dark_grey_highlight};
    font-weight: 700;
`

export const LogisticsProcessSteps = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    
`

export const LogisticsProcessStepsImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 900px;
    object-fit: contain;
`

export const LogisticsProcessContainer = styled.div`
    
    
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
    display: flex;

    @media (max-width: 600px) {
        flex-direction: column;
        
    }
`

export const LogisticsProcessCard = styled.div`
    max-width: 200px;
    margin: 20px;

    @media (max-width: 600px) {
        max-width: 200px;
    }
`

export const LogisticsProcessCardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`