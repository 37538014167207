import "./index.css"
import React, {useState} from 'react';
import { AppContainer, AppLink, LogoImage, WhatsappButton } from './styles/styled.container';

import {ImWhatsapp} from "react-icons/im";
import { HeroContainer, Video, VideoOverlay, HeroSection } from './styles/styled.hero';
import Contact from './Contact';
import NavBar from './NavBar';
import Footer from './Footer';
import Logo from "../src/img/logo.webp";
import About from './About';
import Services from "./Services";
import Projects from "./Projects";
import ProjectListHolder from "./ProjectListHolder";
import Testimonials from "./Testimonials";

function App() {
  const videoRef = React.createRef();
  const videoSpeed = () => {
      if(videoRef.current) videoRef.current.playbackRate = 0.8;
  }

  const [showNav, setShowNav] = useState(false);

  return (
    <AppContainer>
      
      <NavBar show={showNav} setShowNav={setShowNav} />
      <HeroContainer id="home">

            <Video onCanPlay={videoSpeed} ref={videoRef} src="/vids/hero_new_sd.mp4" autoPlay loop muted  />
            <VideoOverlay />
            <HeroSection>
                <LogoImage src={Logo} />

            </HeroSection>
            
      </HeroContainer>

      <About />

      <Services />

      <Projects />

      <ProjectListHolder />
      <Testimonials />

      <Contact />
      
      <AppLink target="_blank" href="https://wa.me/+27824901307"><WhatsappButton><ImWhatsapp size={58} color="black" /></WhatsappButton></AppLink> 

      <Footer />

    </AppContainer>
  );
}

export default App;
