import React from 'react'
import { AppContainer, LogoImage } from './styles/styled.container'
import { HeroContainer, HeroImage } from './styles/styled.hero'
import { Grid, GridItem, LandingButton, LandingCard, LandingCardContainer, LandingCardHolder, LandingCardImage, LandingCardImageContainer, LandingCardText, LandingCardTitle, LandingContainer, LandingHeroButton, LandingHeroButtonContainer, LandingHeroContainer, LandingHeroLogo, LandingHeroSubtext, LandingHeroTextContainer, LandingHeroTextHeading, LandingServiceContainer, LandingServiceTitle } from './styles/styled.landinghero'

import Logo from "../src/img/logo.webp";
import LogisticsVehicleImg from "./img/logistics_vehicle.jpg"
import { useNavigate } from 'react-router-dom'
import { BackImgHolder } from './styles/styled.container'
import LandingCardVisionImg from "./img/landing_card_vision.jpg"
import LandingCardMissionImg from "./img/landing_card_mission.jpg"
import LandingCardValuesImg from "./img/landing_card_values.jpg"
import Footer from './Footer';

function Landing() {
  const navigate = useNavigate();

  return (
    <AppContainer>
      <LandingHeroContainer url={LogisticsVehicleImg}>
        <LandingContainer>
        <LandingHeroLogo>
          <LogoImage src={Logo} />
        </LandingHeroLogo>

        <LandingHeroTextContainer>
          <LandingHeroTextHeading>Courier, Services & Engineering Consultant</LandingHeroTextHeading>
          <LandingHeroSubtext>Built to inspire a positive impact in the local economy.</LandingHeroSubtext>
          <LandingHeroButtonContainer>
          <LandingHeroButton onClick={() => navigate("/logistics")}>Courier Services</LandingHeroButton>
          <LandingHeroButton onClick={() => navigate("/engineering")}>Consulting</LandingHeroButton>
          </LandingHeroButtonContainer>
          
        </LandingHeroTextContainer>
        </LandingContainer>
      </LandingHeroContainer>

      <LandingCardContainer>
        <BackImgHolder />

      <LandingCardHolder swap>
      <LandingCardImageContainer>
        <LandingCardImage src={LandingCardVisionImg} />
      </LandingCardImageContainer>
      <LandingCard>
        <LandingCardTitle>Vision</LandingCardTitle>
        <LandingCardText row={true} centered={true}>
          <div style={{'lineHeight': '1.7'}}>
          To provide a seamless<div className="inline">cost-effective</div>and environmentally friendly services that <div className="inline">supports</div>local businesses and the economy. We achieve this through <div className='inline'>collaboration</div>with other operators, using<div className='inline'>eco-friendly</div>packaging, and continuously improving our service, treatment of employees, and operations. Together, we can make a<div className='inline'>positive impact</div>on the community and the environment.
          </div>
        </LandingCardText>

      </LandingCard>
      </LandingCardHolder>
      
      <LandingCardHolder>
      
      <LandingCard>
        <LandingCardTitle>Mission</LandingCardTitle>
        <LandingCardText><div className='title'>Exceeding Expecations</div>To delight our customer's on a daily basis.</LandingCardText>
        <LandingCardText><div className='title'>Reliability</div>To be reliable and consistent in the services that we provide.</LandingCardText>
        <LandingCardText><div className='title'>Supporting Local</div>To support and help grow the local economy for a better South Africa.</LandingCardText>
        
      </LandingCard>

      <LandingCardImageContainer>
        <LandingCardImage src={LandingCardMissionImg} />
      </LandingCardImageContainer>
      
      </LandingCardHolder>
      
      <LandingCardHolder swap>

      <LandingCardImageContainer>
        <LandingCardImage src={LandingCardValuesImg} />
      </LandingCardImageContainer>

      <LandingCard>
        <LandingCardTitle>Values</LandingCardTitle>
        <LandingCardText><div className='title'>Service Excellence</div>Constantly striving to exceed customer expectations.</LandingCardText>
        <LandingCardText><div className='title'>Integrity</div>Always looking out for you, even when you're not looking.</LandingCardText>
        <LandingCardText><div className='title'>Honesty</div>Truthful in what we say and do.</LandingCardText>
        <LandingCardText><div className='title'>Love</div>Respecting each other in words and actions.</LandingCardText>
        <LandingCardText><div className='title'>Housekeeping</div>We have a place for everything in the right place.</LandingCardText>
      </LandingCard>
      </LandingCardHolder>

      

      

      
      </LandingCardContainer>
      
      <LandingServiceContainer url={LogisticsVehicleImg}>
        <LandingServiceTitle>Looking for a reliable and efficient courier service?</LandingServiceTitle>
        <LandingButton onClick={() => navigate("/logistics")}>Book Now</LandingButton>
      </LandingServiceContainer>

      <Footer />
    </AppContainer>
  )
}

export default Landing