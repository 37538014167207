import React from 'react'
import { TestimonialAuthor, TestimonialContainer, TestimonialItem, TestimonialText } from './styles/styled.testimonials'
import {SectionHeader, Container} from "./styles/styled.container"

function Testimonials() {
  return (
    <Container>
        <SectionHeader>Testimonials</SectionHeader>

    <TestimonialContainer>
        
        <TestimonialItem>
        <TestimonialText>
        “I have acquired the project engineering services of Clayton Naidoo since 2021 and since then he has made a huge impact on the management and execution of our capex projects. I found Clayton to be thorough in the planning of our projects, and his excellent engineering  skills has resulted in its timely execution incorporating proper industrial standards.”
        </TestimonialText>
        <TestimonialAuthor>Rory Govender - Plant Manager NPC Durban</TestimonialAuthor>
        </TestimonialItem>

        <TestimonialItem>
        <TestimonialText>
        “CKCM Solutions is one of Pragma's preferred external service providers in the field of Asset Management.  Pragma appreciates both the expertise and the attitude that Clayton displays in all of our professional engagements”
        </TestimonialText>
        <TestimonialAuthor>Darryl Aberdein - Partner Consultant, Pragma Advisory</TestimonialAuthor>

        </TestimonialItem>
    </TestimonialContainer>
    </Container>
  )
}

export default Testimonials