import styled from "styled-components";
import { Colors } from "./styled.container";


export const ProjectListContainer = styled.div`
    max-width: 1000px;
    display: flex;
    margin-top: 30px;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40px;
    border-radius: 5px;
    z-index: 2;
    border-bottom: 20px solid ${Colors.green};

`

export const ProjectTitle = styled.div`
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 10px;
`

export const ProjectDate = styled.div`
    font-size: 0.8rem;
    width: fit-content;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 5px solid white;
`

export const ProjectDetails = styled.div`
    font-size: 0.9rem;
    line-height: 1.6;
    padding: 20px 20px;
    margin-top: 20px;
    background-color: ${Colors.dark_grey_highlight};
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #434343, #2d2d2d);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #434343, #2d2d2d); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-weight: 600;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: ${Colors.card_background};
    margin-bottom: 15px;
    display: flex;

`