import React, {useState} from 'react'
import { NavContainer, NavInnerContainer, NavLeftContainer, NavRightIconContainer, NavRightContainer, NavItemContainer, NavItem, NavExpandedContainer, NavExpandedItemContainer, NavExpandedItem, NavLeftContainerText, LogisticsNavButton} from "./styles/styled.navbar";
import {GrClose} from "react-icons/gr";
import {FiMenu} from "react-icons/fi";
import { useNavigate } from 'react-router-dom';

function Navbar() {

const [showExpanded, setShowExpanded] = useState(false);
const navigate = useNavigate();

function handleScroll(item) {
    
  const violation = document.getElementById(item); 
  violation.scrollIntoView({ behavior: 'smooth' });

  setShowExpanded(false);
}

function handleLogisticsPage() {
  navigate("/logistics")
}

/*
function handleLogout() {
  logout();
}
*/

function handlePage(page) {

  setShowExpanded(false);
}

// function handleMailClick() {
//   window.open("mailto:clayton@ckcm.co.za", '_blank', 'noopener,noreferrer');
// }

  return (
    <NavContainer>
              <NavInnerContainer>
                <NavLeftContainer>
            
                <NavLeftContainerText onClick={() => handlePage("")}>CKCM Solutions</NavLeftContainerText>
                <LogisticsNavButton onClick={() => handleLogisticsPage()}>Logistics</LogisticsNavButton>
                </NavLeftContainer>
                
                <NavRightContainer>
                  
                  <NavRightIconContainer
                    whileHover={{scale: 0.9, opacity: 0.8}}
                    onClick={() => setShowExpanded(prev => !prev)}>
                      {!showExpanded ? <FiMenu size={32} color="black" /> : 
                      <GrClose size={32} color="white" />}
                  </NavRightIconContainer>
                  
                  <NavItemContainer>
                    <NavItem 
                    onClick={() => handleScroll("home")}>Home</NavItem>
                    <NavItem 
                    onClick={() => handleScroll("about")}>About</NavItem>
                    <NavItem onClick={() => handleScroll("experience")}>Experience</NavItem>
                    
                    <NavItem 
                    onClick={() => handleScroll("projects")}>Projects</NavItem>
                    <NavItem 
                    onClick={() => handleScroll("contact")}>Contact Us</NavItem>

                  </NavItemContainer>
                
                </NavRightContainer>
              </NavInnerContainer>
              <NavExpandedContainer showExpanded={showExpanded}>
                <NavExpandedItemContainer>
                
                    <NavExpandedItem onClick={() => handleScroll("home")}>Home</NavExpandedItem>
                    <NavExpandedItem 
                    onClick={() => handleScroll("about")}>About</NavExpandedItem>
                    <NavExpandedItem onClick={() => handleScroll("experience")}>Experience</NavExpandedItem>
                    <NavExpandedItem 
                    onClick={() => handleScroll("projects")}>Projects</NavExpandedItem>
                    <NavExpandedItem 
                    onClick={() => handleScroll("contact")}>Contact Us</NavExpandedItem>
                </NavExpandedItemContainer>
              </NavExpandedContainer>
            </NavContainer>
  )
}

export default Navbar