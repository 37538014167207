import React from "react";
import { Container } from "./styles/styled.container";

import { ContactHeader } from "./styles/styled.contact";

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Form from "./Form";

function Contact() {
    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);

    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
        progressRef.current = () => {
        if (progress > 100) {
            setProgress(0);
            setBuffer(10);
        } else {
            const diff = Math.random() * 10;
            const diff2 = Math.random() * 10;
            setProgress(progress + diff);
            setBuffer(progress + diff + diff2);
        }
        };
    });

    React.useEffect(() => {
        const timer = setInterval(() => {
        progressRef.current();
        }, 500);

        return () => {
        clearInterval(timer);
        };
    }, []);

    return(
        <Container contact id="contact">
            
            <ContactHeader color="white">Get in touch with us</ContactHeader>
            
            <Form />
            <Box sx={{ width: '100%' }}>
            <LinearProgress color="success" variant="buffer" value={progress} valueBuffer={buffer} />
            </Box>
            
            
        </Container>
    )
}

export default Contact;