import React, { useRef } from "react";
import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { CardItemText } from "./styles/styled.logistics";
import debounce from 'lodash/debounce';

const libraries = ["places"];

// function AddressInputComponent({ isLoaded, setAddress, setAddressIssue, setAddressText }) {
//   const inputRef = useRef();

//   const handlePlaceChanged = () => {
//     const [place] = inputRef.current.getPlaces();
//     if (place) {
      
//       const addressComponents = place.address_components;
//       let country = null;
//       for (let i = 0; i < addressComponents.length; i++) {
//         const addressType = addressComponents[i].types[0];
//         if (addressType === "country") {
//           country = addressComponents[i].short_name;
//           break;
//         }
//       }
      
//       if(country.toLowerCase() !== "za") { 
//         inputRef.current.value = "";
//         setAddressIssue(true)
//         return;
//       }
//       else {
//         setAddressIssue(false)

//         setAddress({
//           address: place.formatted_address,
//           lat: place.geometry.location.lat(),
//           lng: place.geometry.location.lng()
//         })

//         setAddressText(place.formatted_address)
//       }
      
//     }
//   };

//   const options = {
//     bounds: {
//       south: -34.858382,
//       west: 16.344562,
//       north: -22.125366,
//       east: 32.860293,
//     },
//     componentRestrictions: { country: "za" },
//   };

//   if (isLoaded) {
//     return (
//       <StandaloneSearchBox
//         onLoad={(ref) => (inputRef.current = ref)}
//         onPlacesChanged={handlePlaceChanged}
//         options={options}
//       >

//         <CardItemText
//           style={{'width': '100%'}}
//           type="text"
//           onChange={(e) => setAddressText(e.target.value)}
//           className="form-control"
//           placeholder="Enter an address"
//         />

        
//       </StandaloneSearchBox>
//     );
//   } else {
//     return null;
//   }
// }

function AddressInputComponent({ isLoaded, setAddress, setAddressIssue, setAddressText }) {
  const inputRef = useRef();

  // Create a debounced version of handlePlaceChanged
  const handleDebouncedPlaceChanged = debounce(() => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      const addressComponents = place.address_components;
      let country = null;
      for (let i = 0; i < addressComponents.length; i++) {
        const addressType = addressComponents[i].types[0];
        if (addressType === 'country') {
          country = addressComponents[i].short_name;
          break;
        }
      }

      if (country.toLowerCase() !== 'za') {
        inputRef.current.value = '';
        setAddressIssue(true);
        return;
      } else {
        setAddressIssue(false);

        setAddress({
          address: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });

        setAddressText(place.formatted_address);
      }
    }
  }, 1000); // 1000ms (1 second) debounce delay

  const options = {
    bounds: {
      south: -34.858382,
      west: 16.344562,
      north: -22.125366,
      east: 32.860293,
    },
    componentRestrictions: { country: 'za' },
  };

  if (isLoaded) {
    return (
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handleDebouncedPlaceChanged}
        options={options}
      >
        <CardItemText
          style={{ width: '100%' }}
          type="text"
          onChange={(e) => setAddressText(e.target.value)}
          className="form-control"
          placeholder="Enter an address"
        />
      </StandaloneSearchBox>
    );
  } else {
    return null;
  }
}

export function AddressInput({setAddress, setAddressIssue, setAddressText}) {
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: key,
    libraries,
  });

  return isLoaded ? (
    <div>
      <AddressInputComponent
        setAddressText={setAddressText}
        setAddressIssue={setAddressIssue}
        setAddress={setAddress}
        isLoaded={isLoaded}
      />
    </div>
  ) : null;

}

