import styled from "styled-components";
import {Colors} from "./styled.container"

export const ServicesContainer = styled.div`

    display: flex;
    position: relative;
    background-color: ${Colors.dark_grey};
    width: 100%;
    padding: 60px 40px;
    align-items: center;
    justify-content: center;
    

`

export const ServicesImage = styled.img`

    max-width: 1000px;
    align-self: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
`