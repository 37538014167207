import React from 'react'
import { FooterContainer, FooterItem } from './styles/styled.footer'


function handleFooterClick() {
  window.open("https://www.prolificsolutions.co.za", '_blank', 'noopener,noreferrer');
}

function Footer() {
  return (
    <FooterContainer onClick={handleFooterClick}>
        <FooterItem>CKCM Solutions. All Rights Reserved.</FooterItem>
        <FooterItem>Developed by Prolific Solutions.</FooterItem>
    </FooterContainer>
  )
}

export default Footer