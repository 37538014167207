import React, {Fragment, useEffect, useState} from 'react'
import { LogoImage } from './styles/styled.container'
import { CaptchaContainer, CardArrowImage, CardCheckbox, CardCheckboxLabel, CardCircle, CardContainer, CardHolder, CardItem, CardItemRow, CardItemRowItem, CardItemText, CardItemTitle, CardItemWrapper, CardNumber, CardTitle, CardTitleHolder, CardWrapper, ErrorText, HeroContainer, HeroText, HeroTextTitle, LogisticButton, LogisticsContainer, LogisticsFooter, LogisticsHeader, LogisticsHeaderTitle, LogisticsNav, LogisticsNavImage, LogisticsNavImageHolder, LogisticsNavTitle, LogoImageHolder } from './styles/styled.logistics'
import LogoImg from "../src/img/logo_logistics.webp";
import { TextField } from '@mui/material';  
import { Colors } from './styles/styled.container';
import { AddressInput } from './AddressInput';
import OrderManImg from "./img/order_man.png"
import { BackImgHolder } from './styles/styled.container'
import NavImg from "./img/courier.png"
import GoogleReCaptcha  from 'react-google-recaptcha';
import InfoDialog from './InfoDialog';
import ErrorDialog from './ErrorDialog';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import Footer from './Footer';

function LogisticsOrder() {
  const [pickupAddress, setPickupAddress] = useState();
  const [pickupAddressText, setPickupAddressText] = useState("")
  const [deliveryAddressText, setDeliveryAddressText] = useState("");

  const [destinationAddress, setDestinationAddress] = useState();
  const [pickupAddressIssue, setPickupAddressIssue] = useState(false)
  const [deliveryAddressIssue, setDeliveryAddressIssue] = useState(false)
  const [isHuman, setHuman] = useState(false)
  const [pickupSameCustomer, setPickupSameCustomer] = useState(false)
  const [pickupDate, setPickupDate] = useState()
  const [customerInfo, setCustomerInfo] = useState({})
  const [pickupInfo, setPickupInfo] = useState({})
  const [deliveryInfo,setDeliveryInfo] = useState({})
  const [extraInstructions, setExtraInstructions] = useState("")
  const [cardCustomerError, setCardCustomerError] = useState(false)
  const [cardPickupError, setCardPickupError] = useState(false)
  const [cardDeliveryError, setCardDeliveryError] = useState(false)
  const [cardOrderError, setCardOrderError] = useState(false)
  const [errorText, setErrorText] = useState("");
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [deliveryRef, setDeliveryRef] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const captcha_key = "6LfYl8skAAAAADL-tnNpwdkPPgZ9yO0MFxNWzv8x"

  const navigate = useNavigate();
  

  useEffect(() => {
    if(pickupSameCustomer) {
      const name = customerInfo.name ? customerInfo.name : "";
      const contact = customerInfo.contact ? customerInfo.contact : "";
      setPickupInfo(prevState => {
        return {...prevState, 
          name: name,
          contact: contact,
        }
      });
    }
  }, [pickupSameCustomer, customerInfo.contact, customerInfo.name]);

  useEffect(() => {
    if(showErrorDialog || showInfoDialog) setShowLoading(false)
  }, [showErrorDialog, showInfoDialog])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const onCaptchaChange = (value) => {
    setHuman(value !== null);
  };

  function handleSetCustomerInfo(field, value) {
    setCustomerInfo(prevState => {
        return  {...prevState, 
            [field]: value }
    })

    if(pickupSameCustomer) {
        setPickupInfo(prevState => {
            return  {...prevState, [field]: value }
        })
    }
  }

  function handleSetPickupInfo(field, value) {
    setPickupInfo(prevState => {
        return {...prevState, [field]: value}
    })
  }

  function handleSetDeliveryInfo(field, value) {
    setDeliveryInfo(prevState => {
        return {...prevState, [field]: value}
    })
  }

  function handleExtraInstructions(field, value) {
    setExtraInstructions(prevState => {
        return {...prevState, [field]: value}
    })
  }

  function checkCustomerInfoFields() {
    if(!customerInfo.name || !customerInfo.contact || !customerInfo.email ||
        customerInfo.name.length === 0 || customerInfo.contact.length === 0 ||
        customerInfo.email.length === 0) {
            setCardCustomerError(true);
            setErrorText("Please check your details before continuing.")
            return false;
        }
        else {
            setCardCustomerError(false)
            return true;
        }
  }

  function checkPickupInfoFields() {

    if(!pickupInfo.name || !pickupInfo.contact || !pickupAddressText || !pickupDate ||
        pickupInfo.name.length === 0 || pickupInfo.contact.length === 0 || 
        pickupAddressText.length === 0 || pickupDate.length === 0) {
            setCardPickupError(true);
            setErrorText("Please check your pickup details before continuing.")
            return false;
        }
        else {
            setCardPickupError(false)
            return true;
        }
  }

  function checkDeliveryInfoFields() {

    if(!deliveryInfo.name || !deliveryInfo.contact || !deliveryAddressText ||
        deliveryInfo.name.length === 0 || deliveryInfo.contact.length === 0 || deliveryAddressText.length === 0) {
            setCardPickupError(true);
            setErrorText("Please check your delivery details before continuing.")
            return false;
        }
        else {
            setCardPickupError(false)
            return true;
        }
  }

  function checkOrderInfoFields() {
    if(!extraInstructions.order_size || extraInstructions.order_size.length === 0) {
        setCardOrderError(true)
        setErrorText("Please select an order size before continuing.")
        return false;
    } else {
        setCardOrderError(false)
        return true;
    }
  }

  async function handleSubmitOrder() {

    setErrorText("");
    if(!checkCustomerInfoFields() || !checkPickupInfoFields() || !checkDeliveryInfoFields() || !checkOrderInfoFields()) return;

    const pickup_date = pickupDate;
    const pickup_address = pickupAddress;
    const delivery_address = destinationAddress;

    const extra_info = extraInstructions;
    
    setShowLoading(true)
    try {

        const result = await fetch('https://us-central1-ckcm-server.cloudfunctions.net/app/job-create', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Origin": "https://www.ckcm.co.za" // replace with your domain
            },
            body: JSON.stringify({
              customer_info: customerInfo,
              pickup_info: pickupInfo,
              delivery_info: deliveryInfo,
              pickup_date: pickup_date,
              pickup_address: pickupAddress,
              pickup_address_text: pickupAddressText,
              delivery_address: destinationAddress,
              delivery_address_text: deliveryAddressText,
              extra_info: extra_info
            })
          })

          console.log("Status", result.status)

          if(result.status === 200) {
            //console.log("Result status", result.status)
            //const data = await result.json();
            //console.log(data.data.detrack_number);
            //setShowLoading(false)
            //setDeliveryRef(data.data.do_number);
            setShowInfoDialog(true);
          }
          else {
            setShowErrorDialog(true)
            return;
          }

    
    }catch(error) {
      setShowErrorDialog(true)
      return;
    }
    
  }

  function navigateHome() {
    navigate("/")
  }

  return (

        <LogisticsContainer>
        <BackImgHolder />
        <Loader show={showLoading} />

        <ErrorDialog
        open={showErrorDialog}
        setOpen={setShowErrorDialog}
        message="There seems to have been an issue creating your order. Please try again."
        second_message="If this issue persists, please contact info@ckcm.co.za"
        />

        {showInfoDialog &&
        <InfoDialog
        open={showInfoDialog}
        setOpen={setShowInfoDialog}
        
        action={navigateHome}
        title="Job Created"
        content={`Thanks for your order ${customerInfo.name}. We will be in contact with you soon.`} />}
        
        <LogisticsNav onClick={() => navigate("/logistics")}>
            <LogisticsNavImageHolder>
                <LogisticsNavImage src={NavImg} />
            </LogisticsNavImageHolder>
            <LogisticsNavTitle>CKCM Logistics</LogisticsNavTitle>
        </LogisticsNav>

        <LogisticsHeader>
            <LogisticsHeaderTitle>Ready to place an order with us?</LogisticsHeaderTitle>
            <LogoImageHolder>
                <LogoImage src={OrderManImg} />
            </LogoImageHolder>

        </LogisticsHeader>

        <CardHolder>
        <CardContainer errorState={cardCustomerError}>
            <CardTitleHolder>
            <CardCircle><CardNumber>1</CardNumber></CardCircle>
            
            <CardTitle>Your Details</CardTitle>
            </CardTitleHolder>
            <CardCheckboxLabel>
              Enter your information so we can get into contact with you for a free quote.
            </CardCheckboxLabel>
            
            <CardItemWrapper>
            <CardItem style={{'marginTop': '20px'}}>
                <CardItemTitle>Name</CardItemTitle>
                <CardItemText onChange={(e) => handleSetCustomerInfo("name", e.target.value)} />
            </CardItem>
            <CardItem>
                <CardItemTitle>Contact Number</CardItemTitle>
                <CardItemText onChange={(e) => handleSetCustomerInfo("contact", e.target.value)} />
            </CardItem>
            <CardItem>
                <CardItemTitle>Email</CardItemTitle>
                <CardItemText onChange={(e) => handleSetCustomerInfo("email", e.target.value)} />
            </CardItem>
            </CardItemWrapper>
            

        </CardContainer>

        <CardContainer errorState={cardPickupError}>
        <CardTitleHolder>
            <CardCircle><CardNumber>2</CardNumber></CardCircle>
            <CardTitle>Pick up from</CardTitle>

            </CardTitleHolder>
            <CardCheckboxLabel>
                <CardCheckbox type="checkbox" checked={pickupSameCustomer} onChange={(e) => setPickupSameCustomer(e.target.checked)} />
                Set pick up same as my details

            </CardCheckboxLabel>
          <CardItemWrapper>
          
            <CardItem style={{'marginTop': '20px'}}>
                <CardItemTitle>Name</CardItemTitle>
                <CardItemText value={pickupInfo.name ? pickupInfo.name : ""} onChange={(e) => handleSetPickupInfo("name", e.target.value)} />
            </CardItem>
            <CardItem>
                <CardItemTitle>Contact Number</CardItemTitle>
                <CardItemText value={pickupInfo.contact ? pickupInfo.contact : ""} onChange={(e) => handleSetPickupInfo("contact", e.target.value)} />
            </CardItem>
            <CardItem>
                <CardItemTitle>Pick up Address</CardItemTitle>
                <AddressInput 
                setAddress={setPickupAddress}
                setAddressText={setPickupAddressText}
                addressIssue={pickupAddressIssue} 
                setAddressIssue={setPickupAddressIssue}
                />
            </CardItem>
            <CardItem>
                <CardItemTitle>Pickup Date</CardItemTitle>
                <CardItem style={{'marginRight': '10px'}}>
                    <TextField
                          variant='standard'
                          InputProps={{
                            style: { color: `${Colors.dark_grey}`, fontSize: '0.8rem', fontWeight: '500' },
                            disableUnderline: true,
                            }}
                          type="datetime-local"
                          onChange={(e) => setPickupDate(e.target.value)}
                          sx={{
                            'border': `2px solid ${Colors.dark_grey}`,
                            'backgroundColor': `${Colors.card_background}`,
                            'borderRadius': '10px',
                            'padding': '0px 7px',
                            'paddingTop': '2px'

                            }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          />
                </CardItem>

            </CardItem>
          </CardItemWrapper>
            
        </CardContainer>
  
        {/* <CardArrowImage src={ArrowImg} /> */}

        <CardContainer errorState={cardDeliveryError}>
            <CardTitleHolder>
            <CardCircle><CardNumber>3</CardNumber></CardCircle>
            
            <CardTitle>Deliver to</CardTitle>
            </CardTitleHolder>
            <CardCheckboxLabel>
              Where would you like your parcel delivered to?
            </CardCheckboxLabel>
            <CardWrapper>
            <CardItem style={{'marginTop': '20px'}}>
                <CardItemTitle>Name</CardItemTitle>
                <CardItemText onChange={(e) => handleSetDeliveryInfo("name", e.target.value)} />
            </CardItem>
            <CardItem>
                <CardItemTitle>Contact Number</CardItemTitle>
                <CardItemText onChange={(e) => handleSetDeliveryInfo("contact", e.target.value)} />
            </CardItem>
            <CardItem>
                <CardItemTitle>Delivery Address</CardItemTitle>
                <AddressInput
                    setAddressText={setDeliveryAddressText} 
                    setAddress={setDestinationAddress} 
                    addressIssue={deliveryAddressIssue}
                    setAddressIssue={setDeliveryAddressIssue}
                />
            </CardItem>
            </CardWrapper>
            
            
            
        </CardContainer>
        <CardContainer errorState={cardOrderError}>
            <CardTitleHolder>
            <CardCircle><CardNumber>4</CardNumber></CardCircle>
            
            <CardTitle>Order information</CardTitle>
            </CardTitleHolder>
            <CardCheckboxLabel>
              You're almost there! To help us plan more effectively, we'd like to know a bit more about your parcel.
            </CardCheckboxLabel>
            <CardWrapper>
            <CardItem style={{'marginTop': '20px'}}>
                <CardItemTitle>Special Instructions</CardItemTitle>
                <TextField variant="standard"
                placeholder='Got any special instructions for us regarding your parcel?'
                onChange={(e) => handleExtraInstructions("note", e.target.value)}
                sx={{
                    'marginTop': '10px',

                    'border': `2px solid ${Colors.orange}`,
                    'borderRadius': '5px',
                    'color': `${Colors.card_background}`,
                    'padding': '5px 10px',

                    
                    }}
                                  InputProps={{
                                    style: { color: `${Colors.card_background}`, fontWeight: '400', fontSize: '0.8rem' },
                                    disableUnderline: true,
                                      }}
                                  fullWidth 
                                  multiline
                                   />
            </CardItem>

            <CardCheckboxLabel style={{'marginTop': '10px'}}>
                <CardCheckbox type="checkbox" checked={extraInstructions.packaging_required || false} onChange={(e) => handleExtraInstructions("packaging_required", e.target.checked)} />
                I require packaging material for my pick up
            </CardCheckboxLabel>

            <CardItemTitle style={{'margin': '10px 0px', 'marginBottom': '10px'}}>The weight of my package is between:</CardItemTitle>
            
            <CardCheckboxLabel style={{'flexDirection': 'column', 'width': '100%'}}>
            
            <CardItemRow>

              <CardCheckboxLabel style={{'margin': '0px'}}>
                  <CardCheckbox type="radio" value="0" checked={extraInstructions.order_size==="0" || false} onChange={(e) => handleExtraInstructions("order_size", e.target.value)} />
                  0-5kg
              </CardCheckboxLabel>

            
            <CardCheckboxLabel style={{'margin': '0px'}}>
                <CardCheckbox type="radio" value="1" checked={extraInstructions.order_size==="1" || false} onChange={(e) => handleExtraInstructions("order_size", e.target.value)} />
                5kg-10kg
            </CardCheckboxLabel >
            <CardCheckboxLabel style={{'margin': '0px'}}>
                <CardCheckbox type="radio" value="2" checked={extraInstructions.order_size==="2" || false} onChange={(e) => handleExtraInstructions("order_size", e.target.value)} />
                More than 10kg
            </CardCheckboxLabel>
            </CardItemRow>
            </CardCheckboxLabel>
            
            </CardWrapper>
            
            

        </CardContainer>

        </CardHolder>
        

        <CaptchaContainer>
        <GoogleReCaptcha
        sitekey={captcha_key}
        onChange={onCaptchaChange}
        />
        </CaptchaContainer>
        <ErrorText>{errorText}</ErrorText>
        <LogisticButton disabled={!isHuman || showLoading} onClick={handleSubmitOrder}>Request Quote</LogisticButton>
        <Footer />
    </LogisticsContainer>

    
  )
}

export default LogisticsOrder