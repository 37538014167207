import React from 'react'
import LogisticsOrder from './LogisticsOrder'
import { Routes, Route } from 'react-router-dom'
import LogisticsTrack from './LogisticsTrack'
import Logistics from './Logistics'
import Footer from './Footer';

function LogisticsHome() {
  return (
    <>
    <Routes>
        <Route path="/" element={<Logistics />} />
        <Route path="/order" element={<LogisticsOrder />} />
        <Route path="/track" element={<LogisticsTrack />} />
      </Routes>

    </>
    
  )
}

export default LogisticsHome