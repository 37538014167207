import React from 'react'
import { Container, ContainerHolder, ProjectMainContainer, ProjectImageHolder, ProjectImage, ProjectContainer, ProjectHolder, ProjectText, ProjectNoImg, BackImgHolder, SectionHeader } from './styles/styled.container'
import ProjectImg from "./img/projects2.jpg"
import No1Img from "./img/1.png"
import No2Img from "./img/2.png"
import No3Img from "./img/3.png"
import No4Img from "./img/4.png"
import No5Img from "./img/5.png"

function Projects() {
  return (
    <ContainerHolder id="experience">
        <BackImgHolder />
        <Container>

            <SectionHeader>Experience</SectionHeader>

            <ProjectMainContainer>
            <ProjectImageHolder>
                <ProjectImage src={ProjectImg} />
            </ProjectImageHolder>
            
            <ProjectContainer>
                <ProjectHolder>
                    <ProjectNoImg src={No1Img} />
                    <ProjectText>Managed a 3500tph wet concentrator plant with four mining feed sources in Mozambique</ProjectText>
                </ProjectHolder>

                <ProjectHolder>
                    <ProjectNoImg src={No2Img} />
                    <ProjectText>Concluded multiple business improvement initiatives and reliability projects to improve throughput rates.</ProjectText>
                </ProjectHolder>

                <ProjectHolder>
                <ProjectNoImg src={No3Img} />
                    <ProjectText>Successful MRP and EAMS deployments at multiple client sites in the manufacturing and mining sector</ProjectText>
                </ProjectHolder>

                <ProjectHolder>
                <ProjectNoImg src={No4Img} />
                    <ProjectText>Managed asset care centres for multiple large mining clients</ProjectText>
                </ProjectHolder>

                <ProjectHolder>
                <ProjectNoImg src={No5Img} />
                    <ProjectText>Asset Management assessments at multiple client sites in manufacturing and mining sector</ProjectText>
                </ProjectHolder>


            </ProjectContainer>
            </ProjectMainContainer>
            
        </Container>
    </ContainerHolder>
  )
}

export default Projects