import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import VehicleImg from "./img/vehicle.png"
import { IconHolder, IconImage } from './styles/styled.logistics';

export default function InfoDialog(props) {
  const {open, setOpen, title, content, action} = props;

  const handleClose = () => {
    setOpen(false);
  };

  function handleAction() {
    handleClose();
     if(props.action) action();
     
  }

  return (
    <div>
      <Dialog
        PaperProps={{ style: { backgroundColor: '#212529', color: "#f8f9fa" } }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <IconHolder><IconImage src={VehicleImg} />{title}</IconHolder>
          
        </DialogTitle>
        <DialogContent>

          <Typography sx={{'marginTop': '10px', color: "#f8f9fa"}}>{content}</Typography>
          <Divider sx={{marginTop: '10px'}} />
          {/* <Typography sx={{'marginTop': '10px', color: "#FBB03B"}}>Your job reference number is {orderRef}.</Typography> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}