import styled from "styled-components"

export const HeroContainer = styled.div`
    color: aliceblue;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
`

export const HeroMenu = styled.div`
    position: absolute;
    top: 0;
    
    right: 0;
    margin-right: 15px;
    margin-top: 10px;
    z-index: 300;

    &:hover {
        cursor: pointer;
    }
`

export const Video = styled.video`
    
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
    
`

export const VideoOverlay = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(52, 58, 64, 0.2);
`

export const HeroSection = styled.div`
    position: absolute;
    width: 90%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: rgba(33, 37, 41, 0.5);
    border-radius: 40px;
    max-width: 700px;
`

export const HeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`