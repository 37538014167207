import styled from "styled-components";
import { Colors } from "./styled.container";


export const FormContainer = styled.div`
    width: 60%;
    border: 4px solid ${Colors.dark_grey};
    border-radius: 10px;
    background-color: ${Colors.dark_grey};
    padding: 20px;
    max-width: 600px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    align-self: center;
    flex-direction: column;

    @media (max-width: 700px) {
        width: 100%;
    }
`

export const FormRow = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid black;
    margin: 5px;
    padding-bottom: 10px;
`

export const FormItem = styled.div`
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: 450px) {
        flex-direction: column;
        align-items: flex-start;
    }
`
export const FormItemText = styled.div`
    font-size: 0.8rem;
    flex: 1;

    @media (max-width: 450px) {
        margin-bottom: 5px;
    }
`

export const FormItemField = styled.input`
    flex: 1;
    border-radius: 5px;
    padding: 5px;

    @media (max-width: 450px) {
        width: 100%;
    }
`

export const FormTextArea = styled.textarea`
    border-radius: 5px;
    padding: 5px;
`

export const FormButton = styled.div`
    
    margin-top: 10px;
    width: 100%;
    color: ${Colors.white};
    font-weight: 600;
    border: 4px solid ${Colors.green};
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 0.9rem;
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: ${Colors.dark_grey};

    &:hover {
        color: ${Colors.green}
    }
    
`

export const FormMessage = styled.div`
    font-size: 0.8rem;
    color: ${Colors.orange};
    
`