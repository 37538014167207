import React, {useEffect, useRef} from 'react'
import { LogisticsButtonContainer, LogisticsContainer, LogisticsHeroTextContainer, LogisticsHolder, LogisticsHomeButton, LogisticsOrderCard, LogisticsOrderCardButton, LogisticsOrderCardText, LogisticsOrderCardTitle, LogisticsOrderContainer, LogisticsProcessCard, LogisticsProcessCardImage, LogisticsProcessContainer, LogisticsProcessHolder, LogisticsProcessSteps, LogisticsProcessStepsImage, LogisticsProcessTitle, LogisticsServicesCard, LogisticsServicesCardContainer, LogisticsServicesCardText, LogisticsServicesCardTitle, LogisticsServicesContainer, LogisticsServicesLayoutCard, LogisticsServicesLayoutCardImage, LogisticsServicesLayoutCardImageContainer, LogisticsServicesLayoutCardImageContainerRight, LogisticsServicesLayoutCardLeft, LogisticsServicesLayoutCardNumber, LogisticsServicesLayoutCardRight, LogisticsServicesLayoutCardTextContainer, LogisticsServicesLayoutCardTextContainerRight, LogisticsServicesLayoutCardTextExplanation, LogisticsServicesLayoutCardTextTitle, LogisticsServicesLayoutContainer, LogisticsServicesManImage, LogisticsServicesTitle, LogisticsTrustedCard, LogisticsTrustedCardContainer, LogisticsTrustedContainer, LogisticsTrustedImage, LogisticsTrustedTitle, LogisticsVideo, LogisticsVideoOverlay } from './styles/styled.logistics_home'
import LogisticsLogoImg from "./img/hero_logistics_logo.png"

import HeroBoxImg from "./img/logistics_box.png"
import { LogisitcsHeroLogoContainer, LogisticsBoxContainer, LogisticsBoxImage, LogisticsHeroBottomImage, LogisticsHeroBottomImageContainer, LogisticsHeroContainer, LogisticsHeroLogoImage } from './styles/styled.logistics_home'
import { useNavigate } from 'react-router-dom';
import ManImg from "./img/man.png"
import HavalLogoImg from "./img/haval.png"
import MDLogoImg from "./img/md24.png"
import ProdecLogoImg from "./img/prodec_paints.png"

import DemandImg from "./img/demand_service.png"
import NationWideImg from "./img/nationwide_service.png"

import CustomerServiceImg from "./img/customer_service.png"
import RouteServiceImg from "./img/route_service.png"
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import ProcessStepsLandscapeImg from "./img/process_landscape.png"
import ProcessStepsPortraitImg from "./img/process_portrait.png"
import ProcessClickImg from "./img/process_click.png"
import ProcessRequestImg from "./img/process_request.png"
import ProcessSendImg from "./img/process_send.png"
import { useMediaQuery } from '@mui/material';
import Footer from './Footer'

function Logistics() {

  const videoRef = useRef();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const videoSpeed = () => {
      if(videoRef.current) videoRef.current.playbackRate = 0.6;
  }

  
  return (
    <LogisticsHolder>
      <LogisticsContainer>
      

      <LogisticsVideo onCanPlay={videoSpeed} ref={videoRef} src="/vids/highway.mp4" autoPlay loop muted />
      <LogisticsVideoOverlay />
      <LogisticsHeroContainer>
        
        
          <LogisitcsHeroLogoContainer>

            <LogisticsHeroLogoImage src={LogisticsLogoImg} />

          </LogisitcsHeroLogoContainer>
        
        
        
        
    
        <LogisticsHeroTextContainer>We take care of your packages so you don't have to worry.</LogisticsHeroTextContainer>
        <LogisticsButtonContainer>
        
          <LogisticsHomeButton onClick={() => navigate("/logistics/order")}>Place Order</LogisticsHomeButton>
          <LogisticsHomeButton onClick={() => navigate("/logistics/track")}>Track Parcel</LogisticsHomeButton>
        </LogisticsButtonContainer>
        {/* <LogisticsHeroBottomImageContainer>
          <LogisticsHeroBottomImage src={LeavesImg} />
        </LogisticsHeroBottomImageContainer> */}
      </LogisticsHeroContainer>
      
      <LogisticsBoxContainer>
          <LogisticsBoxImage src={HeroBoxImg} />
      </LogisticsBoxContainer>

      </LogisticsContainer>

      <LogisticsTrustedContainer>

          <LogisticsTrustedTitle>Trusted by</LogisticsTrustedTitle>

          <LogisticsTrustedCardContainer>
          <LogisticsTrustedCard>
            <LogisticsTrustedImage src={HavalLogoImg} />
          </LogisticsTrustedCard>

          <LogisticsTrustedCard style={{'paddingBottom': '20px'}}>
            <LogisticsTrustedImage src={MDLogoImg} />
          </LogisticsTrustedCard>

          <LogisticsTrustedCard>
            <LogisticsTrustedImage src={ProdecLogoImg} />
          </LogisticsTrustedCard>

          </LogisticsTrustedCardContainer>
          
      </LogisticsTrustedContainer>

      <LogisticsServicesLayoutContainer>

        <LogisticsServicesLayoutCardLeft>
        
        <LogisticsServicesLayoutCardImageContainer margin_right="100px">
            <LogisticsServicesLayoutCardImage src={DemandImg} />
          </LogisticsServicesLayoutCardImageContainer>

          <LogisticsServicesLayoutCardTextContainer margin_right="50px">
          <ParallaxProvider>
            <Parallax translateX={[0, 40]} speed={10}>
            <LogisticsServicesLayoutCardNumber>1</LogisticsServicesLayoutCardNumber>
            </Parallax>
          </ParallaxProvider>
          
            <LogisticsServicesLayoutCardTextTitle>On-Demand Deliveries</LogisticsServicesLayoutCardTextTitle>
            <LogisticsServicesLayoutCardTextExplanation>We offer a same day express collection, within a 30km radius between Durban
            and the South Coast. We accommodate changes in time or distance up to
            1 hour before the scheduled time.
            <ul>
              <li>Shelly Beach to Durban <span>10:30am</span> daily.</li>
              <li>Deliveries from Durban <span>3:30pm</span> daily.</li>
            </ul>
            </LogisticsServicesLayoutCardTextExplanation>
          </LogisticsServicesLayoutCardTextContainer>
          
        </LogisticsServicesLayoutCardLeft>
        
        <LogisticsServicesLayoutCardRight>
        
        <LogisticsServicesLayoutCardTextContainerRight>
        <ParallaxProvider>
            <Parallax translateX={[50, 0]} speed={15}>
            <LogisticsServicesLayoutCardNumber>2</LogisticsServicesLayoutCardNumber>
            </Parallax>
          </ParallaxProvider>

        <LogisticsServicesLayoutCardTextTitle>Nationwide Routes</LogisticsServicesLayoutCardTextTitle>
        <LogisticsServicesLayoutCardTextExplanation>We offer our customers excellent service in addition to the <span>best rates </span>
         possible for <span>nationwide deliveries</span>. Our national
        courier services will provide the perfect delivery solution for you with
        various delivery time frames.</LogisticsServicesLayoutCardTextExplanation>
        </LogisticsServicesLayoutCardTextContainerRight>
        
        <LogisticsServicesLayoutCardImageContainerRight margin_left="50px">
            <LogisticsServicesLayoutCardImage src={NationWideImg} />
          </LogisticsServicesLayoutCardImageContainerRight>

        </LogisticsServicesLayoutCardRight>

        <LogisticsServicesLayoutCardLeft>
        
        <LogisticsServicesLayoutCardImageContainer margin_right="100px">
            <LogisticsServicesLayoutCardImage src={RouteServiceImg} />
          </LogisticsServicesLayoutCardImageContainer>

          <LogisticsServicesLayoutCardTextContainer margin_right="50px">
          <ParallaxProvider>
            <Parallax translateX={[0, 40]} speed={10}>
            <LogisticsServicesLayoutCardNumber>3</LogisticsServicesLayoutCardNumber>
            </Parallax>
          </ParallaxProvider>
            <LogisticsServicesLayoutCardTextTitle>Route Optimization</LogisticsServicesLayoutCardTextTitle>
            <LogisticsServicesLayoutCardTextExplanation>Our delivery management software allows for <span>multiple drop-offs </span> along a
        a route, allowing you to target more than one location
        within a 30km radius. This means that you can reach more of your clients easier.</LogisticsServicesLayoutCardTextExplanation>
          </LogisticsServicesLayoutCardTextContainer>
          
        </LogisticsServicesLayoutCardLeft>

        <LogisticsServicesLayoutCardRight>

        <LogisticsServicesLayoutCardTextContainerRight>
        <ParallaxProvider>
            <Parallax translateX={[0, 40]} speed={10}>
            <LogisticsServicesLayoutCardNumber>4</LogisticsServicesLayoutCardNumber>
            </Parallax>
          </ParallaxProvider>

        <LogisticsServicesLayoutCardTextTitle>Instant Support</LogisticsServicesLayoutCardTextTitle>
        <LogisticsServicesLayoutCardTextExplanation>Connection matters, so our <span>dedicated internal customer service </span> team is
        always available. Our web-based portal allows you to address any
        issue in your scheduled deliveries to communicate directly with the team if you have a specific query.</LogisticsServicesLayoutCardTextExplanation>
        </LogisticsServicesLayoutCardTextContainerRight>
        
        <LogisticsServicesLayoutCardImageContainerRight margin_left="50px">
            <LogisticsServicesLayoutCardImage src={CustomerServiceImg} />
          </LogisticsServicesLayoutCardImageContainerRight>

        </LogisticsServicesLayoutCardRight>
      </LogisticsServicesLayoutContainer>

      <LogisticsProcessHolder>
        <LogisticsProcessTitle>It's a simple process...</LogisticsProcessTitle>
      
        <LogisticsProcessSteps>
          
          <LogisticsProcessStepsImage src={isSmallScreen ? ProcessStepsPortraitImg : ProcessStepsLandscapeImg} />
        </LogisticsProcessSteps>
        {/* <LogisticsProcessContainer>
        <LogisticsProcessCard>
          <LogisticsProcessCardImage src={ProcessClickImg} />
        </LogisticsProcessCard>

        <LogisticsProcessCard>
          <LogisticsProcessCardImage src={ProcessRequestImg} />
        </LogisticsProcessCard>

        <LogisticsProcessCard>
          <LogisticsProcessCardImage src={ProcessSendImg} />
        </LogisticsProcessCard>
      </LogisticsProcessContainer> */}
      </LogisticsProcessHolder>

      <LogisticsOrderContainer>
        <LogisticsServicesManImage src={ManImg} />
        <LogisticsOrderCard>
          <LogisticsOrderCardTitle>Get a free quote</LogisticsOrderCardTitle>
          <LogisticsOrderCardText>Get in contact with us today and let us provide you with a free quote for your next parcel</LogisticsOrderCardText>
          <LogisticsOrderCardButton onClick={() => navigate("/logistics/order")}>Request Quote</LogisticsOrderCardButton>
        </LogisticsOrderCard>
      </LogisticsOrderContainer>

      
      <Footer />
    </LogisticsHolder>
    
  )
}

export default Logistics